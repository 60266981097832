import { type ClassValue, clsx } from "clsx";
import { remove as removeDiacritics } from "diacritics";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]): string {
  return twMerge(clsx(inputs));
}

export function capitalize(input: string | null): string {
  if (!input) {
    return "";
  }
  const ignoreList = [
    "de",
    "do",
    "da",
    "dos",
    "das",
    "e",
    "em",
    "no",
    "na",
    "nos",
    "nas",
  ];
  return input
    .toLowerCase()
    .split(/[-_\s]+/)
    .map((word) => {
      if (ignoreList.includes(word)) {
        return word;
      }
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
}

/**
 * Converts a string to a URL-friendly filename.
 * Returns the input after removing diacritics, making it lowercase and removing "'" and replacing "/" with "-" and replacing " " with "-" and merging multiple "'" into one.
 *
 * @param input - The input string to be converted.
 * @returns The converted string with diacritics removed, converted to lowercase, and with certain characters replaced.
 */
export function toUrlFilename(input: string): string {
  return normalizeString(input).replace(/\s+/g, "-").replace(/-+/g, "-");
}

/**
 * Normalizes a string by removing diacritics, converting to lowercase, and removing "'" and "/".
 *
 * @param input - The input string to be normalized.
 * @returns The normalized string with diacritics removed, converted to lowercase, and with certain characters replaced.
 */
export function normalizeString(input?: string): string {
  if (!input) {
    return "";
  }
  return removeDiacritics(input)
    .toLowerCase()
    .replace(/'/g, "")
    .replace(/\//g, "-")
    .replace(/-+/g, " ")
    .replace(/\s+/g, " ");
}
